import React from 'react';

import { Router } from '@reach/router';

import NotFound from '@components/Routing/NotFound';
import ClubAndDistrictReports from '@presenters/web/pages/ClubAndDistrictReports';

import RequireLogin from '../components/Auth/RequireLogin';

import { FEATURE_SHAREPOINT, isEnabled } from '@utils/features';

const ClubAndDistrictReportsPage: React.FC<{
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix } }) => {
  const isSharepointEnabled = isEnabled(FEATURE_SHAREPOINT);

  if (!isSharepointEnabled) {
    return <NotFound />;
  }

  return (
    <RequireLogin>
      <Router basepath={`/${languagePrefix}`}>
        <ClubAndDistrictReports
          path="/club-and-district-reports/:oktaId/:reportTechnicalName/:dataSelector"
          oktaId="is set from path"
        />
      </Router>
    </RequireLogin>
  );
};

export default ClubAndDistrictReportsPage;
